@import "../css/variables.module";

.excalidraw {
  .Toast {
    $closeButtonSize: 1.2rem;
    $closeButtonPadding: 0.4rem;

    animation: fade-in 0.5s;
    background-color: var(--button-gray-1);
    border-radius: 4px;
    bottom: 15px;
    box-sizing: border-box;
    cursor: default;
    left: 50%;
    margin-left: -165px;
    padding: 8px 0;
    position: absolute;
    text-align: center;
    min-width: 400px;
    height: fit-content;
    z-index: 999999;
    display: flex;
    justify-content: flex-start;
    padding: 10px 15px;
    border: 1px solid transparent;

    .Toast__texts {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 1;
      margin-left: 15px;
      padding: 3px 0px;

      .Toast__status {
        font-size: 1em;
        text-transform: capitalize;
        margin-bottom: 5px;
        color: #fff;
        font-weight: 700;
      }

      .Toast__message {
        // padding: 0 $closeButtonSize + ($closeButtonPadding);
        color: var(--popup-text-color);
        white-space: pre-wrap;
        text-transform: capitalize;
        color: rgb(204, 232, 205);
        font-size: 0.85em;
        text-align: left;
      }
    }

    .close {
      padding: $closeButtonPadding;
      border: 0.1rem solid #ccc;
      transition: 0.2s ease background-color;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      position: relative;
      bottom: 27px;
      left: 27px;
      display: flex;
      justify-content: center;
      visibility: hidden;

      &:hover {
        background-color: var(--button-hover-bg);
      }
      &:active {
        border-color: tomato;
      }

      .ToolIcon__icon {
        width: $closeButtonSize;
        height: $closeButtonSize;

        svg {
          color: #fff;
        }
      }
      .ToolIcon__icon:active {
        background-color: transparent;
        border: 0;
      }
    }
    &:hover {
      .close {
        visibility: visible;
      }
    }
  }

  /* --------- Positions --------- */

  .top-toast {
    top: 15px;
    left: 50%;
    margin-left: -165px;
  }
  .bottom-toast {
    bottom: 15px;
    left: 50%;
    margin-left: -165px;
  }
  .top-right-toast {
    top: 15px;
    right: 15px;
    left: initial;
    margin-left: 0px;
  }
  .top-left-toast {
    top: 15px;
    left: 15px;
    margin-left: 0px;
  }
  .bottom-right-toast {
    bottom: 15px;
    right: 15px;
    left: initial;
    margin-left: 0px;
  }
  .bottom-left-toast {
    bottom: 15px;
    left: 15px;
    margin-left: 0px;
  }

  /* --------- Status --------- */
  .normal-toast {
    background-color: var(--button-gray-1);
  }

  //-----------> Error toast
  .Toast.error-toast {
    background-color: #fff0ed;
    border-color: #d32f2f;

    .close {
      border-color: #d32f2f;
      background-color: #fff0ed;

      svg {
        color: #d32f2f;
      }

      &:hover {
        background-color: #ff8671;
        svg {
          color: #fff;
        }
      }
    }
    .Toast__icon {
      svg {
        fill: #d32f2f;
      }
    }
    .Toast__status {
      color: #d32f2f;
    }
    .Toast__message {
      color: #d32f2f;
    }
    .ToolIcon__icon:hover {
      background-color: #ff8671;
    }
  }

  //-----------> Success toast
  .success-toast {
    background-color: rgb(237, 247, 237);
    border-color: #2e7d32;

    .Toast__icon {
      svg {
        fill: #2e7d32;
      }
    }
    .close {
      border-color: #2e7d32;
      background-color: rgb(237, 247, 237);
      .ToolIcon__icon {
        svg {
          color: #2e7d32;
        }
      }
      &:hover {
        background-color: #2e7d32;
        svg {
          color: #fff;
        }
      }
    }
    .Toast__texts {
      .Toast__status {
        color: #235e26;
      }
      .Toast__message {
        color: #235e26;
      }
    }
    .ToolIcon__icon:hover {
      background-color: transparent;
    }
  }

  //-----------> Info toast
  .info-toast {
    background-color: rgb(229, 246, 253);
    border-color: #0288d1;

    .Toast__icon {
      svg {
        fill: #0288d1;
      }
    }
    .close {
      border-color: rgb(1, 67, 97);
      background-color: rgb(229, 246, 253);
      .ToolIcon__icon {
        svg {
          color: rgb(1, 67, 97);
        }
      }
      &:hover {
        background-color: rgb(1, 67, 97);
        svg {
          color: #fff;
        }
      }
    }
    .Toast__texts {
      .Toast__status {
        color: rgb(1, 67, 97);
      }
      .Toast__message {
        color: rgb(1, 67, 97);
      }
    }
    .ToolIcon__icon:hover {
      background-color: transparent;
    }
  }

  //-----------> Warning toast
  .warning-toast {
    background-color: #ffff64;

    .close {
      border-color: chocolate;
      &:hover {
        background-color: #ffff9b;
      }
    }
    .Toast__message {
      color: chocolate;
    }
    .ToolIcon__icon:hover {
      background-color: #ffff9b;
    }
  }

  @media (max-width: 480px) {
    .Toast {
      width: 100%;
      margin: 0 auto;
      right: 0;
      left: 0;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
