@import "./mixins";
@import "./colors";

#cover-sheet-section_reset_btn {
  @include upload_popup_btn;
  margin-top: 0;
  padding-top: 4px;
  padding-bottom: 4px;
}

.MuiFormLabel-root.Mui-focused {
  color: $fi-primary-10 !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $fi-primary-10 !important;
}

.building-height-input, .occupancy-load-input, .building-area-input {
  display: flex;
  align-items: center;
  position: relative;
}

.building-area-input {
  margin-bottom: 16px;
}