@mixin flex_center($direction: row) {
  display: flex;
  flex-direction: $direction;
  align-items: center;
  justify-content: center;
}

.float-map-actions_container {
  position: absolute;
  width: fit-content;
  top: 76px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2000;
  @include flex_center(column);

  .float-map_view_actions {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    @include flex_center(row);

    .drawing-view-icon,
    .map-view-icon {
      cursor: pointer;
      margin: 0 15px;
      scale: 1.25;
      color: #444;
      transition: 0.3s ease color;
    }
  }

  .float-map-save_discard_actions {
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: 0;
    border-radius: 0 0 5px 5px;
    @include flex_center(row);

    button {
      background-color: transparent;
      border: 0;
      border-left: 1px solid #ccc;
      height: 2.25rem;
      width: 2.25rem;
      transition: 0.2s ease-in-out background-color;
      @include flex_center(row);

      &:active {
        svg {
          scale: 0.8;
        }
      }

      &:disabled {
        pointer-events: none;
      }
    }

    .float-map-save_action {
      &:hover {
        background-color: rgba(0, 240, 0, 0.1);
      }
      &:active {
        background-color: rgba(0, 240, 0, 0.2);
      }
    }

    .float-map-discard_action {
      border-left: 0;

      svg {
        width: 22px;
        height: 22px;
      }

      &:hover {
        background-color: rgba(255, 99, 71, 0.1);
      }
      &:active {
        background-color: rgba(255, 99, 71, 0.2);
      }
    }
  }
}

/* RESPONSIVENESS */
@media (max-width: 1294px) {
  .float-map-actions_container {
    .float-map_view_actions {
      top: 5rem;
      padding: 4px 0px;

      .drawing-view-icon,
      .map-view-icon {
        margin: 0 8px;
        scale: 1;
      }
    }

    button {
      height: 1.85rem !important;
      width: 1.85rem !important;
    }
  }
}
