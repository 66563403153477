.floating-save-discard-buttons-container {
  position: absolute;
  width: fit-content;
  height: fit-content;
  left: 50%;
  transform: translate(-50%, 0);
  top: 74px;
  z-index: 2;

  .floating-save-discard_actions {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    background-color: var(--island-bg-color);
    box-shadow: var(--shadow-island);
    border-radius: var(--border-radius-lg);
    padding: 4px;
    transition: box-shadow 0.5s ease-in-out;
  }

  .floating-save-discard-divider {
    width: 1px;
    height: 1.5rem;
    align-self: center;
    background-color: var(--default-border-color);
    margin: 0 0.5rem;
  }

  .floating-save-discard_action {
    width: 35px;
    height: 35px;
    outline: none;
    border: none;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    transition: 0.25s ease-in-out all;

    &:disabled {
      pointer-events: none;
    }
  }

  .floating-save-discard_discard {
    &:hover {
      background-color: rgba(255, 99, 71, 0.1);
    }
    &:active {
      background-color: rgba(255, 99, 71, 0.2);
    }
    svg {
      font-size: 1.3rem;
    }
  }

  .floating-save-discard_save {
    &:hover {
      background-color: rgba(0, 240, 0, 0.1);
    }
    &:active {
      background-color: rgba(0, 240, 0, 0.2);
    }
    svg {
      font-size: 1.5rem;
    }
  }

  .floating-save-discard_save_only {
    &:hover {
      width: 100px;
      background-color: rgba(0, 240, 0, 0.1);

      svg {
        opacity: 0;
      }

      &::after {
        content: "Save";
        position: absolute;
        width: 100px;
        color: #333;
        font-size: 15px;
      }
    }
  }
}

.floating-save-discard-buttons-container_vertical {
  display: flex;
  flex-direction: column;
}
