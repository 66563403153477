@import "../ExcalidrawAPI/css/variables.module";
@import url("https://fonts.googleapis.com/css2?family=B612+Mono&display=swap");
@import "./mixins";
@import "./variables";
@import "./colors";

/* <----- Mixins -----> */
@mixin detected_info_field {
  padding: 3px 6px;
  border: 1px solid #ccc;
  font-size: 14px;
  border-radius: $border_radius;
  background: $fi-primary-1;
  flex-grow: 1;
}

@mixin ellipse_text($width) {
  width: $width;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

* {
  @include initial-reset;
}

.upload-manager-icon {
  position: absolute;
  top: 50%;
  left: 0px;
  background-color: #fff;
  display: flex;
  padding: 0.5rem;
  z-index: 1301;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 0.5rem 0.5rem 0;
  color: #555;
}

.upload-manager-puller_box_open {
  transition: 0.2s ease-in-out background-color,
    transform 0.5s cubic-bezier(0, 0, 0.2, 1) 0s;
}

.upload-manager-puller_box_close {
  border-radius: 0.5rem;
  transition: 0.2s ease-in-out background-color,
    transform 0.5s cubic-bezier(0.4, 0, 0.6, 1) 0s;
}

.upload-popup-controllers {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  border-bottom: 1px solid #ebebeb;
  position: sticky;
  top: 0;
  z-index: 2;
  background: #fff;

  .upload-popup-close-btn {
    display: inline-block;
    margin-bottom: 0;

    button {
      margin: 0;
    }
  }
}

.close-btn-section {
  @include close_btn_container;
}

#occupy-entire-property_label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
}

/* <--------- For Property Info Inputs Container ---------> */
.property-dominant-occupancy {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 12px;
  margin-top: 8px;
  position: relative;

  &:after {
    content: "Dominant Property Occupancy";
    position: absolute;
    top: -9px;
    left: 7px;
    padding: 0 5px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.8);
    background-color: #fff;
    font-family: Assistant, system-ui, BlinkMacSystemFont, -apple-system,
      Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  }

  .property-dominant-occupancy-details {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
}

/* <--------- For Drawing Info Inputs Container ---------> */
.upload-popup-content {
  padding: 14px 16.5px;
  flex-grow: 1;

  .info-inputs-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    #detected-line-width-textfield {
      padding: 8.5px 14px;
    }

    #page-no-textfield {
      padding: 5px 12px 5px;
    }

    .Checkbox {
      margin: 0;

      .Checkbox-box {
        width: 19px;
        height: 19px;
        margin: 0;
        box-shadow: 0 0 0 2px rgba(74, 71, 177, 1);

        button {
          margin: 0;
        }
      }

      &:hover:not(.is-checked) {
        .Checkbox-box:not(:focus) {
          box-shadow: 0 0 0 2px rgba(74, 71, 177, 1);
        }
        .Checkbox-box {
          background-color: rgba(74, 71, 177, 0.03);
        }
      }
    }

    .Checkbox.is-checked .Checkbox-box {
      background-color: rgba(74, 71, 177, 0.7);
    }

    input {
      padding: 16.5px 14px;
      border: none;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        background-color: #fff !important;
      }
    }
  }
}

.drawing-occupancies-container {
  margin-bottom: 12px;
  position: relative;
}

.dominant-occupancy {
  display: flex;
  position: relative;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 20px 12px 12px 12px;
  border-radius: $border_radius;

  &::after {
    content: "Dominant Project Occupancy";
    position: absolute;
    top: -9px;
    left: 7px;
    padding: 0 4px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.8);
    background-color: #fff;
    font-family: $usedTextFont;
  }
}

.add-occupancy_button {
  display: inline-block;
  margin: auto;
  color: $fi-primary-10;
  border-bottom: 0.1rem solid transparent;
  cursor: pointer;
  font-size: 17px;
  font-family: $usedTextFont;
  font-weight: 600;
  padding-left: 24px;
  transition: 0.2s ease border-bottom;

  &:hover {
    border-bottom: 0.1rem solid $fi-primary-10;
  }
  &:active {
    color: $fi-primary-10;
  }
}

.dominant-occupancy-details {
  display: flex;
  justify-content: space-between;
}

#projects-dropdown-container_uploader {
  margin-top: 8px;
  margin-bottom: 16px;
}

#place-on-map_button,
#connect-two-points {
  @include upload_popup_btn();
}

@media (max-width: 576px) {
  #place-on-map_button {
    margin-bottom: 16px;
  }
}

.invalid-input-warning {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 173px;
}

/* <--------- For Drawing Name Container ---------> */
.drawing-name-input {
  position: relative;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
/* <--------- For Drawing Nomenclature Container ---------> */
.drawing-nomenclature-input {
  position: relative;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
/* <--------- For Drawing Page No Container ---------> */
.drawing-page-no_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .drawing-page-no-input {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;

    .page-no_OF_last {
      position: relative;
      top: 2px;
      margin-left: 6px;
      font-size: 1rem;
      font-weight: 500;
      // font-family: $usedTextFont;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      user-select: none;
      color: rgba(0, 0, 0, 0.63);
    }
  }
}

/* <--------- For connect-two-points Button ---------> */
#connect-two-points {
  margin: 11px auto 12px;
}

button.connect-two-points:disabled {
  pointer-events: none;
  background: transparent;
  border: 1px solid $fi-primary-4;
  color: $fi-primary-4;
}

/* <--------- For Detected Info Container ---------> */
.detected-info {
  position: relative;
  border: 1px solid #ccc;
  border-radius: $border_radius;
  font-family: $usedTextFont;
  padding: 14px;
  padding-top: 0;
  margin-top: 8px;

  .detected-info-header {
    position: relative;
    left: -6px;
    bottom: 13px;
    padding: 0 5px;
    font-size: 14px;
    color: #555;
    background-color: #fff;
    text-transform: capitalize;
  }

  .real-line-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
/* <--------- For Floor Area ---------> */
.floor-area-container {
  margin-top: 12px;
  display: flex;

  .work-area-input-container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
}

.select-floor-area-btn-container {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

#select-floor-area_btn {
  @include upload_popup_btn();
  padding: 3.5px 10px;
  margin: 0;
}

/* <--------- For Check Box ---------> */
#is-lowest-level_label {
  display: flex;
  width: 100%;
  margin-left: -1px;
  margin-top: 10px;
  justify-content: space-between;
}

.Checkbox {
  margin: 4px 0.3em;
  display: flex;
  align-items: center;

  cursor: pointer;
  user-select: none;

  &:hover:not(.is-checked) .Checkbox-box:not(:focus) {
    box-shadow: 0 0 0 2px $fi-primary-7;
  }

  &:hover:not(.is-checked) {
    svg {
      display: block;
      opacity: 0.3;
    }
  }

  &:active {
    .Checkbox-box {
      box-shadow: 0 0 2px 1px inset $fi-primary-3 !important;
    }
  }

  &:hover {
    .Checkbox-box {
      background-color: fade-out($fi-primary-2, 0.1);
    }
  }

  &.is-checked {
    .Checkbox-box {
      background-color: $fi-primary-7;
      svg {
        display: block;
        color: #fff;
      }
    }
    &:hover .Checkbox-box {
      background-color: $fi-primary-4;
      svg {
        display: block;
        opacity: 0.3;
      }
    }
  }

  .Checkbox-box {
    width: 18px;
    height: 18px;
    padding: 0;
    flex: 0 0 auto;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 2px $fi-primary-7;
    background-color: $fi-primary-1;
    border-radius: $border_radius;
    color: $fi-primary-7;
    border: 0;
    cursor: pointer;

    &:focus {
      box-shadow: 0 0 0 3px $fi-primary-7;
    }

    svg {
      display: none;
      width: 16px;
      height: 16px;
      stroke-width: 3px;
    }
  }

  .Checkbox-label {
    display: flex;
    align-items: center;
  }

  .excalidraw-tooltip-icon {
    width: 1em;
    height: 1em;
  }
}
.order-drawer_cell_checkbox {
  margin: 0;
  margin-top: 3px;
}

/* ---------- Buttons Section ---------- */
.buttons-section {
  width: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  padding: 16.5px;
  z-index: 1;
  background: #fff;
}

.upload-popup-submit-btn {
  @include submit-btn;
}

/* ---------- Loader ---------- */
.loader-menu-item {
  width: 100%;
  @include center-content(column);
}

.loading-word {
  margin-top: 5px;
}

.MuiInputBase {
  color: red;
  font-size: 40px;
}

.upload-accordion-summary {
  min-height: 60px !important;
  padding: 4px 6px 4px 13px !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
}

.MuiAccordionSummary-content {
  margin: 16px 0 !important;
}

/* ---------- Responsiveness ---------- */
@media (min-width: 1440px) {
  .detected-info .real-line-field .real-line-input {
    font-size: 15px;
  }
  .detected-info .calculated-scale-value {
    font-size: 17px;
  }
}
@media (max-width: 1440px) {
  .detected-info .line-pixels-field .pixels-value-text {
    font-size: 15px;
  }
}
@media (max-width: 1200px) {
  .detected-info .line-pixels-field .pixels-value-container {
    width: 130px;
  }
  .detected-info .line-pixels-field .pixels-value-text {
    width: 60%;
    font-size: 15px;
  }
  .detected-info .real-line-field .real-line-input {
    font-size: 13px;
  }
}
@media (max-width: 1024px) {
  .detected-info .line-pixels-field .pixels-value-text {
    font-size: 14px;
    width: 62%;
  }
}
@media (max-width: 768px) {
  .detected-info {
    padding: 0px 7px 7px;
  }

  .detected-info .detected-info-header {
    left: -3px;
  }
  .detected-info .line-pixels-field .pixels-value-text {
    font-size: 13px;
  }

  .detected-info .field-label {
    width: 30%;
    font-size: 12px;
    font-weight: 500;
  }

  .detected-info .real-line-field .real-line-input {
    width: 33%;
    font-size: 12px;
  }

  .pixels-per-unit_text .pixels-unit {
    font-size: 13px;
  }

  .detected-info .line-pixels-field .pixels-unit {
    right: 19px;
    padding-left: 19px;
    font-size: 13px;
  }
  .detected-info .pixels-per-unit .pixels-per-unit_text {
    font-size: 13px;
  }
}

/* ---------- Responsiveness ---------- */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 1199px) {
  .select-floor-area-btn-container {
    margin-bottom: 12px;
  }
}

#project-header,
#property-header,
#cover-sheet-header,
#floors-header {
  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
  }
}

.record-id-input,
.system-description-input,
.project-address-input {
  margin-bottom: 16px;
  position: relative;
  display: flex;
  align-items: center;
}

#system-description-textfield,
#project-address-textfield,
#record-id-textfield {
  text-transform: capitalize;
  font-size: 13px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#system-description-textfield,
#record-id-textfield {
  width: calc(100% - 80px);
}

.blinking-accordion-summary.upload-accordion-summary {
  animation: blinking 0.8s 3 forwards;
}

#project-header,
#property-header,
#cover-sheet-header,
#floors-header {
  background-color: $fi-primary-1;
}

/* ---------- Animation ---------- */

@keyframes blinking {
  0% {
    background: rgba(255, 0, 0, 0.1);
  }
  50% {
    background: rgba(91, 87, 209, 0.027);
  }
  100% {
    background: rgba(255, 0, 0, 0.1);
  }
}

.missed {
  position: relative;
  border-color: red !important;
  color: red;

  .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
  }
  label {
    color: red;
  }
}
