.project-summary-fields,
.code-reference-fields,
.page-props-fields {
  border: solid 1px #ccc;
  padding: 10px 10px 0px 10px;
  position: relative;
  border-radius: 5px;
  margin: 8px 0;

  &::before {
    content: "Project Summary";
    position: absolute;
    top: -9px;
    left: 7px;
    padding: 0 3px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.8);
    background-color: #fff;
    font-family: Assistant, system-ui, BlinkMacSystemFont, -apple-system,
      Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  }
}

.code-reference-fields {
  margin: 16px 0px;
  padding-top: 20px;

  &::before {
    content: "Code Reference";
  }
}

.page-props-fields {
  margin: 16px 0px;
  padding-top: 20px;

  &::before {
    content: "Page Props";
  }
}
