@import "./variables";
@import "./colors";

@mixin center_content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer-cell_container {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 210px;
  margin: 0px 15px 12px 0;
  overflow: hidden;
  // transform: translate3d(0, 0, 0);
}
.order-drawer_cell {
  position: absolute;
  width: 200px;
  max-width: 220px;
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: $border_radius;
  cursor: move;
  overflow: hidden;
  transition: 0.1s ease-in-out border, 0.2s ease-in-out scale;

  .order-drawer_cell_image {
    width: 100%;
    max-height: 75%;
    object-fit: contain;
  }

  .drawer-cell_actions_container {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.22);
    transition: 0.5s ease-in-out top;
    @include center_content;
  }

  .drawer-cell_actions_container_on {
    top: 0%;
  }

  .order-drawer_cell_actions {
    position: absolute;
    border: 1px solid #ccc;
    display: flex;
    width: 60px;
    border-radius: 30px;
    justify-content: space-between;
    box-shadow: 0px 0px 3px 1px #eee;
    overflow: hidden;

    .order-drawer_cell_btn {
      display: flex;
      width: 1.75rem;
      height: 1.75rem;
      background-color: #fff;
      outline: none;
      border: none;
      border-radius: 50%;
      overflow: hidden;
    }

    .save_cell_btn {
      color: green;
      background-color: #daf9da;
      box-shadow: none;
      transition: 0.25s all ease-in-out;
      border: 1px solid green;

      &:hover {
        background-color: #b4ffb4;
      }
    }

    .discard_cell_btn {
      color: #f74d2f;
      background-color: #fcedea;
      border: 1px solid #fa9d8c;
      box-shadow: none;
      transition: 0.25s all ease-in-out;

      &:hover {
        background-color: #fcd4cd;
      }
    }

    .cell_btn_freezed {
      color: #888;
      border-color: #888;
      background-color: #ddd;
      pointer-events: none;
      cursor: not-allowed;
    }

    .order-drawer_save_order_icon,
    .order-drawer_discard_order_icon {
      margin: auto;
      top: 0;
      transition: 0.3s ease top;
    }

    .cell_btn_freezed_save {
      .order-drawer_save_order_icon {
        position: absolute;
        top: 100%;
      }
    }

    .order-drawer_spinner_container {
      position: absolute;
      width: 28px;
      height: 28px;
      margin: auto;
      top: 100%;
      transition: 0.3s ease top;
    }

    .spinner_on {
      top: 0%;
    }
  }

  .order-drawer_icon_insert_after {
    transform: rotate(90deg);
  }

  .order-drawer-cell_texts_menu_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    height: 41px;

    .order-drawer-cell_name_input {
      border: 0;
      text-align: center;
      text-transform: capitalize;
      font-size: 17px;
      padding: 0;
      width: 60%;

      &::selection {
        color: #fff;
        background-color: rgb(105, 101, 219);
      }

      &:disabled {
        user-select: none;
      }

      &:disabled:hover {
        background-color: transparent;
      }

      &:hover {
        background-color: #fff;
        cursor: default;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px #6965db;
        cursor: text;
      }

      &:active {
        outline: none;
        box-shadow: none;
        cursor: none;
      }

      &:read-only {
        outline: none;
        box-shadow: none;
        cursor: default;
      }
    }

    #drawer-cell-menu_delete_option {
      width: 100px;
      color: tomato;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .drawer-cell-menu_delete_option_icon {
      width: 18px;
    }
    .order-drawer_cell_menu_btn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      color: #444;
      right: 8px;
      bottom: 8px;
      font-size: 1rem;
      min-width: 0;
    }
  }

  .order-drawer_cell_checkbox {
    position: absolute;
    top: 8px;
    left: 8px;
    margin: 0;

    .Checkbox-box {
      margin: 0;
      width: 18px;
      height: 18px;
      border-radius: $border_radius;
      background-color: var(--color-primary-light);
      border: 1px solid var(--color-primary);
      box-shadow: none !important;
      padding: 2px;
    }

    &.Checkbox:hover {
      .Checkbox-box {
        background-color: var(--color-primary-light);
      }
    }

    &.is-checked {
      .Checkbox-box {
        background-color: var(--color-primary) !important;

        svg {
          color: var(--color-primary-light);
        }
      }
    }
  }

  &:hover {
    border: 1px solid #6965db;
  }
}

.order_drawer_cell_active {
  border-width: 2px;
  border-color: $falcon-primary-color;
  animation: 0.3s ease-in-out activate_cell;
}

.order_drawer_cell_selected {
  border-width: 2px;
  border-color: #6965db;
  animation: 0.3s ease-in-out activate_cell;
}

.order_drawer_cell_active_sign {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 8px;
  bottom: 8px;
  background-color: $falcon-primary-color;
  border-radius: 50%;
  cursor: default;
}

.grabbing-cell {
  cursor: grabbing;
}

/*======== RESPONSIVENESS ======= */
@media (max-width: 375px) {
  .order-drawer_cell {
    width: 200px;
  }
}

@media (min-width: 1280px) and (max-width: 1570px) {
  .order-drawer_cell {
    max-width: 200px;
  }
}

/*======== ANIMATION ======= */
@keyframes activate_cell {
  0% {
    scale: 1;
  }
  50% {
    scale: 0.95;
  }
  100% {
    scale: 1;
  }
}
