@import "./mixins";

* {
  @include initial-reset;
}
@mixin address_props {
  color: #5b57d1;
  font-weight: 500;
  font-size: 13px;
}

@mixin label_content {
  color: #444;
  min-width: 155px;
  padding: 0 25px 5px 5px;
  overflow-wrap: break-word;
  font-size: 13px;
  text-transform: capitalize;
}

$table_border_width: 2px;

$used_font_family: Assistant, system-ui, BlinkMacSystemFont, -apple-system,
  Segoe UI, Roboto, Helvetica, Arial, sans-serif;

.table_page {
  font-family: $used_font_family;
  text-align: center;
  width: 100%;
  height: 100%;
}

.project-summary-puller_box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: fit-content;
  left: 0;
  top: 45%;
  z-index: 4;
  transform: translate(0, -50%);
  background-color: #e3e2fe;
  border-radius: 0px 8px 8px 0px;
  font-family: $used_font_family;
  transition: 1000 ease-in-out width;

  .project-summary-word-text {
    width: 0;
    font-size: 16px;
    margin-bottom: 3px;
    overflow: hidden;
    white-space: nowrap;
    transition: 0.2s ease-in-out width;
  }

  .summary-icon-container {
    display: flex;
    align-items: center;
    width: 28px;
    height: 28px;

    .summary-icon {
      width: 100%;
      height: 100%;
    }
  }

  .project-summary-puller_icon {
    color: #868686;
    transition: 0.5s ease-in-out all 0.1s;
  }

  .project-summary-puller_mobile_icon {
    display: none;
  }

  .project-summary-puller_text {
    display: inline-block;
    position: relative;
    transform: rotate(270deg) translate(25%, -50%);
    top: 50%;
    color: #333;
    font-weight: 700;
  }

  &:hover {
    cursor: pointer;
    background-color: #d1d0f7;

    .project-summary-word-text {
      width: 115px;
    }
  }
}

.project-summary-icon {
  position: absolute;
  top: 50%;
  left: 0px;
  background-color: #fff;
  display: flex;
  padding: 0.5rem;
  z-index: 1301;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 0.5rem 0.5rem 0;
  color: #555;
}

.project-summary-puller_box_open {
  transition: 0.2s ease-in-out background-color,
    transform 0.5s cubic-bezier(0, 0, 0.2, 1) 0s;
}

.project-summary-puller_box_close {
  border-radius: 0.5rem;
  transition: 0.2s ease-in-out background-color,
    transform 0.5s cubic-bezier(0.4, 0, 0.6, 1) 0s;
}

#syncing-loader {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: #6965db;
  width: 35px;
  height: 30px;
  border-radius: 17px;

  .Spinner {
    circle {
      stroke: #fff;
    }
  }
}

.project-drawing-info-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  z-index: 4;
  width: 25%;
  border-radius: 8px;
  padding: 4px 15px;
  opacity: 0.7;
  transition: all 0.3s;
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.05),
    0px 0px 3.12708px rgba(0, 0, 0, 0.0798),
    0px 0px 0.931014px rgba(0, 0, 0, 0.1702);

  &:hover {
    opacity: 1;
  }

  .ellipsis {
    width: 100%;
    text-overflow: ellipsis; /* enables ellipsis */
    white-space: nowrap; /* keeps the text in a single line */
    overflow: hidden; /* keeps the element from overflowing its parent */
  }
}

.info-texts {
  width: calc(100% - 50px);
}

.info-label_text {
  @include label_content;
}

.info-label_text_table {
  @include label_content;
  font-size: 1rem;
}

.info-label_address {
  min-width: 55px;
  display: inline-block;
  text-align: left;
  @include address_props;
}

.info-label_colon {
  @include address_props;
}

.base-line {
  display: none;
  height: 1px;
  width: 100%;
  margin: auto;
  background-color: #ddd;
  position: relative;
  bottom: 2px;
}

.drawing-info-dialog_forward_btn {
  display: flex;
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translate(0px, -50%);
  color: #888;
}

.forward-btn_to-right {
  animation: to_right 0.5s ease-in-out 1 forwards;
}

.info-close-button {
  @include close_btn_container;
}

.info-container-table {
  background-color: transparent;
  font-family: $used_font_family;
  border-radius: 5px;

  .horizontal-separator {
    height: 1px;
    width: 100%;
    background-color: #fff;
  }

  .info-label {
    display: flex;
    position: relative;
    flex-direction: column;
    text-transform: capitalize;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.3s ease background-color;

    &:hover {
      background-color: #5b57d110;
    }
  }

  .info-label_read_only {
    background-color: #5b57d110;
    cursor: auto;

    .info-label_address {
      cursor: auto;
    }
  }

  .last-info-table-cell {
    margin-bottom: 0;
  }

  .info-label_address {
    min-width: none;
    padding: 5px;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #5b57d110;
    @include address_props;
  }
}

.drawing-info-speed-dial_container {
  z-index: 1;
  width: 35px;
  height: 35px;
  position: relative;
}

.drawing-info-dialog {
  .drawing-info-dialog_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    font-family: Assistant, system-ui, BlinkMacSystemFont, -apple-system,
      Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    border-bottom: 1px solid #eee;
    text-transform: capitalize;

    .drawing-info-dialog_back_btn {
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: transparent;
      background-color: transparent;
      color: #444;
      transition: 0.25s ease background-color;
      border-radius: 50%;

      &:hover {
        background-color: #ebebeb;
        cursor: pointer;
      }
    }

    .drawing-info-dialog_back_btn_disabled {
      pointer-events: none;
      color: #ccc;
    }

    .drawing-info-dialog_close_btn {
      margin-bottom: 0;
    }

    .drawing-info-dialog_close_btn:active {
      background-color: #ebebeb;
      border-radius: 50%;
    }
  }

  .drawing-info-dialog_divider {
    width: 100%;
    height: 1px;
    background-color: #ebebeb;
  }

  .drawing-info_content {
    height: 100%;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: $used_font_family;
    padding: 15px;
    overflow: scroll;
    scroll-behavior: smooth;
    text-align: center;

    .drawing-info-path {
      text-transform: capitalize;
      text-align: left;
      margin-bottom: 15px;
      color: #777;
      font-size: 14px;
    }

    .drawing-info-path_active {
      color: #5b57d1;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.hide {
  display: none;
}

@media print {
  .project-drawing-info-container {
    display: none;
  }
}

@media (max-width: 425px) {
  .drawing-info-dialog {
    .drawing-info-dialog_header {
      font-size: 1rem;
    }

    .info-label_address {
      font-size: 0.8rem;
    }

    .info-label_text {
      font-size: 0.8rem;
    }
    .info-label_text_table {
      font-size: 0.9rem;
    }
  }
}

/*---------- Animation ---------- */
@keyframes to_right {
  0% {
    right: 4px;
  }
  50% {
    right: 14px;
  }
  100% {
    right: 4px;
  }
}

@media (max-width: 729px) {
  .project-summary-puller_box {
    height: 2.25rem;
    width: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .project-summary-word-text {
      display: none !important;
    }

    .project-summary-puller_icon,
    .project-summary-puller_text {
      display: none;
    }
  }
}
