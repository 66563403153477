/* <----- Fonts -----> */
$usedTextFont: Assistant, system-ui, BlinkMacSystemFont, -apple-system, Segoe UI,
  Roboto, Helvetica, Arial, sans-serif;

.occupancy-field_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-top: 8px;
  transition: 3s ease display;

  &:last-of-type {
    margin-bottom: 6px;
  }
}
.occupancy-field {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 20px 64px 12px 12px;
  border-radius: 4px;

  .occupancy-field-details {
    display: flex;
    justify-content: space-between;
  }
}

.occupancy-main-label {
  position: absolute;
  top: -9px;
  left: 7px;
  padding: 0 4px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.8);
  background-color: #fff;
  font-family: $usedTextFont;
}

.occupancy-field_icon-button {
  position: absolute;
  right: 12px;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(255, 99, 71, 0.2);
  border: 0.1rem solid rgba(255, 99, 71, 0.2);
  overflow: hidden;
  cursor: pointer;
  transition: 0.2s ease background-color;

  &:hover {
    background-color: rgba(255, 99, 71, 0.3);
  }
  .remove-occupancy_minus_icon {
    position: relative;
    bottom: 0;
    transition: 0.2s ease bottom;
  }
  .remove-occupancy_trash_icon {
    position: absolute;
    top: 100%;
    transition: 0.2s ease top;
  }
  &:hover .remove-occupancy_minus_icon {
    bottom: 100%;
  }
  &:hover .remove-occupancy_trash_icon {
    top: 50%;
    transform: translate(0, -50%);
  }
  &:active {
    border-color: rgba(255, 99, 71, 0.855);
    background-color: rgba(255, 99, 71, 0.855);
  }
}
.occupancy-field_disabled-button {
  pointer-events: none;
  opacity: 0.4;
}
.occupancy-field_hidden-button {
  display: none;
}

/*---------- Responsiveness --------- */
@media (max-width: 1200px) {
  .occupancy-field_icon-button {
    width: 2rem;
    height: 2rem;
  }
}
@media (max-width: 600px) {
  .occupancy-field_icon-button {
    width: 1.6rem;
    height: 1.6rem;
    background-color: transparent;
    border: 0.1rem solid transparent;
  }
}
