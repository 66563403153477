@import "./variables";
@import "./colors";

@mixin initial-reset {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@mixin center-content($flexDirection: row) {
  display: flex;
  flex-direction: $flexDirection;
  justify-content: center;
  align-items: center;
}

@mixin upload_popup_btn {
  display: flex;
  padding: 3.5px 10px;
  margin: 16px auto 0;
  font-size: 14px;
  line-height: 1.75;
  border-radius: $border_radius;
  border: 1px solid $fi-primary-5;
  cursor: pointer;
  text-transform: uppercase;
  color: $fi-primary-10;
  background-color: $fi-primary-0;
  user-select: none;
  transition: 0.3s ease background-color, 0.3s ease color;

  &:hover {
    background-color: $fi-primary-1;
    border: 1px solid $fi-primary-7;
  }
  &:disabled {
    pointer-events: none;
    background: transparent;
    border: 1px solid $fi-primary-4;
    color: $fi-primary-4;
  }
}

@mixin submit-btn {
  width: auto;
  height: auto;
  margin-top: 0;
  min-width: 87px;
  padding: 5px 15px;
  border-radius: 4px;
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  background-color: $fi-primary-9;

  &:hover {
    background-color: $fi-primary-10;
  }
}

@mixin close_btn_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}
