@import "./mixins";

$button_bg: #5b57d1;
$button_active_bg: #5b57d141;
$button_disabled_bg: #dddddd;

* {
  @include initial-reset;
}

.loading-button {
  width: 140px;
  height: 50px;
  background-color: $button_bg;
  border: 0;
  border-radius: 50px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
  outline: none;
  color: #fff;
  transition: all 0.3s;
  user-select: none;
  @include center-content;
}

.loading-button:not([disabled]):active {
  background-color: #fff;
}

.loading-button:disabled {
  background-color: $button_disabled_bg;
  pointer-events: none;
  cursor: auto;
}

.button-loader {
  width: 30px;
}
