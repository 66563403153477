.value-slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .value-slider-icon {
    display: flex;
    margin-left: 5px;
  }

  .value-slider-container-slider {
    margin: 0 10px 0 7px;
  }

  .value-slider_textfield {
    width: 20%;
    input {
      &:focus {
        box-shadow: none;
      }
      font-size: 14px;
    }
  }
}
