@import "./mixins";

#projects-dropdown-container_opener {
  margin-bottom: 16px;
}

.close-btn-section {
  width: 100%;
  display: flex;
  justify-content: end;
}

.no_drawings_option_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 6px 16px;
  user-select: none;

  & > span:first-child {
    color: #999;
  }
}

.loader-menu-item {
  width: 100%;
  @include center-content(column);
}

.loading-word {
  margin-top: 5px;
}
/* ---------- Buttons Section ---------- */
.saved-drawing-dialog .buttons-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0 0 0;
}
