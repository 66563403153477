.map-component {
  height: calc(100vh - 115px);
  width: 100%;
  position: absolute;
  top: 62px;
}

.map_flash_container {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff9;
  z-index: 1000;
  transition: 0.5 ease-in-out display;
}

.map_loader_container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;

  .map_loader_background {
    background-color: #fff9;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .map_loader_spinner {
    width: 2rem;
    height: 2rem;
    margin-bottom: 15px;
  }

  .map_loader_text {
    color: #000;
    font-size: 20px;
    z-index: 1;
  }
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.leaflet-top .leaflet-control {
  left: 5px;
  top: 4px;
}

.map-north-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 0.8;
  width: 75px;
  height: 75px;
  background-color: #fff;
  border: 1px solid #888;
  border-radius: 50%;
  padding: 10px;

  .map-north-indicator_img {
    width: 100%;
    height: 100%;
  }
}

/* RESPONSIVENESS */
@media (max-width: 1294px) {
  .map-component {
    height: calc(100vh - 114px);
    width: 100%;
    position: absolute;
    top: 62px;
  }
  .leaflet-top .leaflet-control {
    margin-top: 0px;
    top: 20px;
  }
}

g:focus {
  outline: none;
}

path:focus {
  outline: none;
}

@media (max-width: 729px) {
  .map-component {
    height: calc(100vh - 125px);
    width: 100%;
    position: absolute;
    top: 62px;
  }
  .leaflet-top .leaflet-control {
    margin-top: 0px;
    top: 20px;
  }
}
