@import "../css/variables.module";

@mixin default_buttons_settings {
  display: flex;
  column-gap: 0.5rem;
}

.excalidraw {
  .confirm-dialog {
    &-buttons_end {
      justify-content: flex-end;
      @include default_buttons_settings;
    }
    &-buttons_start {
      justify-content: flex-start;
      @include default_buttons_settings;
    }
    &-buttons_center {
      justify-content: center;
      @include default_buttons_settings;
    }

    .confirm-button_active {
      transition: 0.3s ease background-color;
      &:active {
        background-color: #4c4999;
      }
    }

    .confirm-button_disabled {
      background-color: #eee;
      border-color: #eee;
      pointer-events: none;
    }
  }
}
