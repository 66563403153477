.sliders-pullers-container {
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translate(0%, -50%);
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #fff;

  .sliders-pullers-content {
    width: 0rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2.5rem;
    background-color: transparent;
    overflow-x: hidden;
    transition: 0.3s all ease-in-out;
  }

  .sliders-pullers-divider {
    width: 1px;
    height: 1.5rem;
    align-self: center;
    background-color: var(--default-border-color);
    margin: 0 0.5rem;
  }

  .slider-pullers-puller {
    position: relative;
    width: 2rem;
    height: 2rem;
    padding: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0.5rem;
    background-color: transparent;
    transition: 0.3s all ease-in-out;

    svg {
      width: 0.75em;
      height: 0.75em;
    }

    &:hover {
      background-color: var(--color-gray-10);
    }
  }

  .slider-pullers-puller_disabled {
    svg {
      opacity: 0.4;
    }
    &:hover {
      background-color: transparent;
    }
  }

  #slider-pullers-puller_pages {
    svg {
      width: 0.7em;
      height: 0.7em;
    }
  }

  .sliders-pullers-main-puller {
    width: 2.5rem;
    height: 2.5rem;

    .sliders-pullers-main-puller_start_icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s all ease-in-out;
    }

    .sliders-pullers-main-puller_end_icon {
      position: absolute;
      opacity: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s all ease-in-out;
    }

    svg {
      width: 1em;
      height: 1em;
    }

    &:hover {
      background-color: #fff;
    }
  }

  &:hover {
    .sliders-pullers-main-puller {
      cursor: auto;
    }

    .sliders-pullers-content {
      width: 9.5rem;
    }
    .sliders-pullers-main-puller_start_icon {
      opacity: 0;
    }
    .sliders-pullers-main-puller_end_icon {
      opacity: 1;
    }
  }
}

.sliders-pullers-container_disabled {
  pointer-events: none;

  svg {
    fill: #999;
  }
}
