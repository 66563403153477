$falcon-primary-color: #f7931e;

$fi-primary-0: rgba(74, 71, 177, 0.03);
$fi-primary-1: rgba(74, 71, 177, 0.1);
$fi-primary-2: rgba(74, 71, 177, 0.2);
$fi-primary-3: rgba(74, 71, 177, 0.3);
$fi-primary-4: rgba(74, 71, 177, 0.4);
$fi-primary-5: rgba(74, 71, 177, 0.5);
$fi-primary-6: rgba(74, 71, 177, 0.6);
$fi-primary-7: rgba(74, 71, 177, 0.7);
$fi-primary-8: rgba(74, 71, 177, 0.8);
$fi-primary-9: rgba(74, 71, 177, 0.9);
$fi-primary-10: rgb(74, 71, 177);
