.north-heading-input_container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
}

.north-heading-input {
  color: var(--text-primary-color);
  border: 1.5px solid var(--input-border-color);
  padding: 0.75rem;
  white-space: nowrap;
  border-radius: var(--space-factor);
  background-color: var(--input-bg-color);
  flex-grow: 1;
  margin-right: 10px;

  &:not(:focus) {
    &:hover {
      background-color: var(--input-hover-bg-color);
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--focus-highlight-color);
  }
}
