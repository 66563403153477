.map_screenshot_btn {
  position: absolute;
  z-index: 1000;
  bottom: 14px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 150px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 0;
  border-radius: 5px;
  transition: 0.15s ease-in-out background-color;

  &:hover {
    background-color: #f5f5f5;
  }

  &:active {
    border-color: var(--color-primary);
  }
}
