@import "./mixins";

@mixin label_style($labelText: "") {
  content: $labelText;
  width: auto;
  position: absolute;
  top: -10px;
  left: 10px;
  padding: 0 5px;
  font-size: 13px;
  color: (0, 0, 0, 0.6);
  background-color: #fff;
}

.area-field-main-container {
  position: relative;
  margin-bottom: 10px;
  margin-top: 0;

  #area-field-accordion-summary {
    border-bottom: 1px solid #ccc;

    .area-field-select-box {
      position: absolute;
      width: 20px;
      height: 20px;
      margin: 0;
      margin-right: 16px;
      z-index: 0;
      opacity: 0;
      transition: 0.3s opacity ease-in-out;

      .Checkbox-box {
        margin: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--color-primary-light);
        border: 1px solid var(--color-primary);
        box-shadow: none !important;
        padding: 0px;
      }

      &.Checkbox:hover {
        .Checkbox-box {
          background-color: var(--color-primary-light);
        }
      }

      &.is-checked {
        position: relative;
        z-index: 1;
        opacity: 1;

        .Checkbox-box {
          background-color: var(--color-primary) !important;

          svg {
            color: var(--color-primary-light);
          }
        }
      }
    }

    &:hover {
      .area-field-select-box {
        position: relative;
        z-index: 1;
        opacity: 1;
      }
      .area-field-color-circle {
        display: none;
      }
    }
  }
  .area-field-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      padding: 16.5px 14px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

#area-field-accordion {
  margin-bottom: 10px;
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.area-field-content {
  position: relative;
  flex-grow: 1;

  .MuiFormLabel-root.Mui-focused {
    top: 0 !important;
  }

  &:last-child {
    margin-bottom: 0;
  }

  #area-field-textfield,
  #area-field-area-textfield {
    outline: none;
    border: none;
    padding: 8.5px 14px;

    &:hover:not(:disabled) {
      background-color: transparent;
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
#area-field-area-textfield {
  background-color: rgba(0, 0, 0, 0.03);

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
}

.area-field-label-settings {
  .area-field-label-settings_content_spread_btn {
    @include upload_popup_btn();
    margin-top: 0;
    border-radius: 0;
    height: 16px;
    border-top-color: transparent;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease,
      border-radius 0.6s ease-in-out;

    svg {
      font-size: 1rem;
      transform: rotate(180deg);
      transition: all 0.6s ease-in-out;
    }
  }

  .active_spread_btn {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    svg {
      transform: rotate(0deg);
    }
  }
  .area-field-label-settings_content {
    height: 0px;
    margin-bottom: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: height 0.6s ease-in-out, border-color 0s 0.5s ease-in-out;
    border: 1px solid #aaa;
    border-top: 0;
    border-bottom-color: transparent;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .area-field-value-slider_fontsize {
      border: 1px solid #bbb;
      position: relative;
      border-radius: 6px;
      width: calc(100% - 24px);

      input {
        padding: 8.5px 14px;
        text-align: center;
      }

      &:after {
        @include label_style("Font Size");
      }
    }

    .area-field-value_color {
      position: relative;
      width: calc(100% - 24px);
      border: 1px solid #bbb;
      border-radius: 6px;
      margin-top: 12px;
      padding: 12px;

      &::after {
        @include label_style("Color");
      }
    }

    .area-field-label-settings_reposition_btn {
      @include upload_popup_btn();
      height: 39px;
      width: calc(100% - 24px);
      display: flex;
      flex-direction: column;
      font-size: 13px;
      margin-top: 12px;
      border-radius: 6px;
      overflow: hidden;

      .area-field-label-settings_reposition_btn_start {
        position: relative;
        top: 18px;
        transition: 0.2s top ease-in-out;
      }

      .area-field-label-settings_reposition_btn_end {
        position: relative;
        top: -41px;
        font-size: 20px;
        transition: 0.2s top ease-in-out;
      }

      &:hover {
        .area-field-label-settings_reposition_btn_start {
          top: 44px;
        }
        .area-field-label-settings_reposition_btn_end {
          top: -7px;
        }
      }

      svg {
        font-size: 1.2rem;
      }
    }
  }

  .spread {
    height: 170px;
    border-bottom-color: #aaa;
  }
}

.area-field-cell-actions {
  width: fit-content;
  height: fit-content;
  margin-left: 10px;
  border: 1px solid #999;
  border-radius: 3rem;
  padding: 3px;

  .area-field-cell-action {
    position: relative;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    outline: none;
    border: 1px solid #888;
    border-radius: 50%;
    margin-bottom: 10px;
    overflow: hidden;
    color: #555;
    transition: 0.2s all ease-in-out;

    &:hover {
      background-color: var(--color-gray-10);
    }

    &:active {
      border-color: #6965db;
      scale: 0.8;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .area-field-cell-action_start_icon {
      position: relative;
      bottom: 0;
      transition: 0.2s ease bottom;
    }

    .area-field-cell-action_end_icon {
      position: absolute;
      top: 100%;
      transition: 0.2s ease top;
    }
    &:hover .area-field-cell-action_start_icon {
      bottom: 100%;
    }
    &:hover .area-field-cell-action_end_icon {
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .area-field-cell-action_delete {
    border: 0.1rem solid rgba(255, 99, 71, 0.2);
    transition: 0.2s ease background-color;

    &:hover {
      background-color: rgba(255, 99, 71, 0.3);
    }

    &:active {
      border-color: rgba(255, 99, 71, 0.855);
      background-color: rgba(255, 99, 71, 0.855);
    }
  }

  .area-field-pick_color_action {
    position: relative;
    margin-bottom: 10px;
  }

  .area-field-color_picker {
    position: absolute;
    right: calc(100% + 4px);
    top: 18px;
    z-index: 3;
  }
}

.area-field-dimensions-container {
  margin: 10px 0px;
}

.area-field-dimensions-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.area-field-select-area-button {
  width: 100%;
  height: 36px;
  border-radius: 5px;
  background-color: transparent;
  color: var(--icon-fill-color);
  font-size: 16px;
  outline: none;
  border: 1px solid #ddd;
  margin-top: 10px;
  transition: 0.2s all ease-in-out;

  &:hover {
    background-color: var(--color-gray-10);
  }

  &:active {
    border-color: #6965db;
  }
}

.area-field-color-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 16px;
  border: 1px solid #999;
}

.area-field-floating-save-discard {
  position: relative;
  top: 0;
  margin-top: 16px;
  z-index: 1;
}
