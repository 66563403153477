@import "./mixins";

$hoverBorderColor: #625ee0;

.customized-library-item {
  @include center-content;
  position: relative;
  width: 60px;
  height: 60px;
  margin-left: 3px;
  padding: 10px;
  border: 3px solid transparent;
  border-radius: 5px;
  transition: 0.2s all;
  cursor: pointer;

  img {
    width: 75%;
  }

  &:hover {
    border-color: $hoverBorderColor;
  }
}

img.manual-pull-station-img,
img.strobe-img {
  width: 65%;
}
