@import "./mixins";

* {
  @include initial-reset;
}

.customized-library_reset_btn {
  @include center-content;
  padding: 0.625rem;
  width: var(--button-width, var(--default-button-size));
  height: var(--button-height, var(--default-button-size));
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: var(--button-border, var(--default-border-color));
  border-radius: var(--border-radius-lg);
  cursor: pointer;
  background-color: var(--button-bg, var(--island-bg-color));
  color: var(--button-color, var(--text-primary-color));
  width: var(--lg-button-size);
  height: var(--lg-button-size);
  padding: 0;

  &:hover {
    background-color: var(--button-hover-bg, var(--island-bg-color));
    border-color: var(--button-hover-border, var(--default-border-color));
  }

  &:active {
    background-color: var(--button-active-bg, var(--island-bg-color));
    border-color: var(--button-active-border, var(--color-primary-darkest));
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.devices-container {
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
  width: 92%;
  height: -moz-fit-content;
  height: fit-content;
  margin: 20px auto;
  padding: 20px 0 0;
  border: 1px solid #ccc;
  border-radius: 6px;
  overflow: visible;
}
.devices-container_content {
  width: 100%;
  height: 100%;
  max-height: 450px;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.devices-separator {
  width: 92%;
  height: 1px;
  background-color: #ccc;
  margin: auto;
}

.devices-container_header {
  position: absolute;
  top: -9px;
  left: 21px;
  color: #888;
  padding: 0px 4px;
  background-color: #fff;
  font-size: 13px;
  font-weight: 400;
}
