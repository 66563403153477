.custom-badge-container {
  background-color: red;
  width: 20px;
  height: 20px;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  font-size: 13px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
