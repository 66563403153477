/* Styles for screens smaller than 768px (mobile devices) */

@media screen and (max-width: 1920px) {
  .info-texts {
    display: flex;
    flex-direction: column;
  }

  .buttons-section {
    margin-top: 24px;
  }
}

@media screen and (max-width: 1269px) {
  .project-drawing-info-container {
    height: 35px;
  }

  .info-texts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ellipsis {
    width: 100%;
  }

  .buttons-section {
    margin-top: 12px;
  }

  .base-line {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .project-drawing-info-container {
  }
}

@media screen and (max-width: 728px) {
  .info-texts {
    width: 88%;
    display: flex;
  }
}

@media screen and (max-width: 576px) {
  .project-drawing-info-container {
    height: 48px;
  }

  .info-texts {
    width: 85%;
    display: flex;
    flex-direction: column;
  }

  .info-close-button {
    margin-bottom: 12px;
  }

  .connect-two-points {
    margin: 0 auto;
  }

  .excalidraw .welcome-screen-center__heading {
    font-size: 1.05rem;
  }
}
