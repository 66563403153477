@import "./mixins";

$toggler_background: #fff;
$toggler_border: rgba(0, 0, 0, 0.185);
$toggler_background_hover: rgb(247, 247, 247);
$toggler_active_border: #5b57d1;
$toggler_text: #a4a3a3;

* {
  @include initial-reset;
}

.library-toggler {
  width: 9.2rem;
  height: 36px;
  padding: 2px;
  border-radius: 5px;
  transition: box-shadow 0.5s ease, transform 0.5s ease;
  cursor: pointer;
  border: 1px solid $toggler_border;
  background: $toggler_background;
  transition: all 0.2s;
  @include center-content;

  .library-toggler-icon {
    width: 16px;
    height: 16px;
  }
  &:hover {
    background: $toggler_background_hover;
  }

  &:active {
    border-color: $toggler_active_border;
  }
}

.library-toggler-btn {
  background-color: transparent;
  border: 0;
  outline: none;
  font-size: 0.75rem;
  color: $toggler_text;
  margin-left: 7px;

  &:active {
    background: transparent;
  }
}

/* =========== Responsiveness =========== */
@media (max-width: 1023px) {
  .library-toggler {
    width: 36px;
    height: 36px;
    position: relative;
    display: block;
    padding: 0;
    border-radius: 7px;

    .library-toggler-icon {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .library-toggler-btn {
      font-size: 0;
    }
  }
}

/* =========== Mobile View =========== */
.library-toggler_mobile {
  width: 100%;
  position: relative;
  display: block;
  padding: 0;
  border: 0;
  border-radius: 0;

  .library-toggler-icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
