@import "./mixins";

* {
  @include initial-reset;
}

@mixin main_menu_grid_label {
  // position: absolute;
  font-size: 13px;
  color: black;
}

.dropdown-menu-container {
  z-index: 4;
}

/* ========== Grid Size Slider ========== */
.customized-grid-size-slider {
  width: 100%;
  border: 0.1rem solid #ddd;
  border-radius: 0.4rem;
  padding: 0.4rem 1rem;
  padding-bottom: 0;
  margin-top: 0.3rem;
}

.menu-item_disabled {
  opacity: 0.5;
  pointer-events: none;
}

.sign-out_menu-option {
  color: tomato !important;
}
