$used_font_family: Assistant, system-ui, BlinkMacSystemFont, -apple-system,
  Segoe UI, Roboto, Helvetica, Arial, sans-serif;

.react-resizable-handle-e {
  right: 0;
  top: 0;
  width: 2px;
  padding: 0;
  height: 100%;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  cursor: col-resize;
  background-image: none;
}

.order-drawer_close_btn {
  margin-bottom: 0;
}

.order-drawer_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 11px 5px 19px;
  font-family: $used_font_family;
  border-bottom: 1px solid #eee;
}

.order-drawer_divider {
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
  margin: 5px auto 25px;
}

.order-drawer_no_drawings {
  height: 94%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p,
  h3 {
    max-width: 200px;
  }
}

.order-drawer_content {
  height: 100%;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: $used_font_family;
  padding: 0 0 15px 17px;
  overflow: scroll;
  scroll-behavior: smooth;
  text-align: center;
}

.order-drawer_content_freezer_on {
  background-color: transparent;
}

.order-drawer_content_freezer {
  position: absolute;
  width: 100%;
  height: 0%;
  top: -1000%;
  left: 0;
  background-color: transparent;
  z-index: 1;
  transition: 0.3s ease-in-out top;

  .order-drawer_content_spinner_container {
    position: relative;
    top: 500%;
    transform: translate(0, -500%);
  }
}
.order-drawer_content_freezer_on {
  height: 1000%;
  top: 0%;
}

.order-drawer-puller_box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: fit-content;
  left: 0;
  top: 53%;
  z-index: 4;
  transform: translate(0, -50%);
  background-color: #e3e2fe;
  border-radius: 0px 8px 8px 0px;
  font-family: $used_font_family;
  transition: 1000 ease-in-out width;

  .pages-manager-word-text {
    width: 0;
    font-size: 16px;
    margin-bottom: 3px;
    overflow: hidden;
    white-space: nowrap;
    transition: 0.2s ease-in-out width;
  }

  .pages-icon-container {
    display: flex;
    align-items: center;
    width: 28px;
    height: 28px;

    .pages-icon {
      width: 100%;
      height: 100%;
    }
  }

  .order-drawer-puller_icon {
    color: #868686;
    transition: 0.5s ease-in-out all 0.1s;
  }

  .order-drawer-puller_mobile_icon {
    display: none;
  }

  .order-drawer-puller_text {
    display: inline-block;
    position: relative;
    transform: rotate(270deg) translate(25%, -50%);
    top: 50%;
    color: #333;
    font-weight: 700;
  }

  &:hover {
    cursor: pointer;
    background-color: #d1d0f7;

    .pages-manager-word-text {
      width: 100px;
    }
  }
}

.order-drawer-icon {
  position: absolute;
  top: 50%;
  left: 0px;
  background-color: #fff;
  display: flex;
  padding: 0.5rem;
  z-index: 1301;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 0.5rem 0.5rem 0;
  color: #555;
}

.order-drawer-puller_box_open {
  transition: 0.2s ease-in-out background-color,
    transform 0.5s cubic-bezier(0, 0, 0.2, 1) 0s;
}

.order-drawer-puller_box_close {
  border-radius: 0.5rem;
  transition: 0.2s ease-in-out background-color,
    transform 0.5s cubic-bezier(0.4, 0, 0.6, 1) 0s;
}

.order-drawer_save_order_icon {
  width: 100% !important;
  height: 100% !important;
}

.order-drawer_save_order_spinner {
  display: none;
  width: 100%;
  height: 100%;
}

.order-drawer_btn {
  display: flex;
  background: #f3f3f3;
  scale: 1;
  outline: none;
  border: 0;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  color: #666;
  box-shadow: 0px 0px 6px 3px #ccc;
  transition: 0.25s all ease-in-out;

  &:disabled {
    filter: grayscale(1);
    pointer-events: none;

    .order-drawer_save_order_icon {
      display: none;
    }

    .order-drawer_save_order_spinner {
      display: block;
    }
  }

  &:hover {
    background: #ffffff;
  }
  &:active {
    scale: 0.75;
  }
}

.order-drawer_scroll_icon {
  width: 100% !important;
  height: 100% !important;
  transform: rotate(0deg);
  transition: 0.3s ease-in-out transform !important;
}

.order-drawer_btn_scroll {
  display: none;
  position: absolute;
  right: 25px;
  bottom: 25px;
}

.scroll_icon_down {
  transform: rotate(180deg);
}

.order-drawer_buttons_container {
  position: absolute;
  height: 6.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  bottom: 25px;
  left: 25px;
  border-radius: 55px;
  padding: 1px;
  box-shadow: 0px 0px 3px 1px #ccc;
  visibility: visible;
  transition: 0.25s all ease-in-out;

  .save_order_btn {
    bottom: 85px;
    color: green;
    background-color: #daf9da;
    box-shadow: none;
    transition: 0.25s all ease-in-out;

    &:hover {
      background-color: #b4ffb4;
    }
  }

  .discard_order_btn {
    color: #f74d2f;
    background-color: #ffffff75;
    border: 1px solid #fa9d8c;
    box-shadow: none;
    transition: 0.25s all ease-in-out;

    &:hover {
      background-color: #fcd4cd;
    }
  }

  .order-drawer_discard_order_icon {
    width: 85% !important;
    height: 85% !important;
    margin: auto;
  }
}

.hidden {
  visibility: hidden;
}

.pages_manager_actions_confirm {
  margin-bottom: 20px;
}

.order-drawer_loader_container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1050;
}

.pages-manager-actions-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 14px;

  .page-manager-export-btn {
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 50%;
    padding: 5px;

    &:hover:not(:disabled) {
      background-color: var(--button-hover-bg);
    }
    &:disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }
}
.selected-checkbox_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0px;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 12px 0;
  background-color: #ffff;

  .order-drawer_select_checkbox.Checkbox {
    margin: 0;

    .Checkbox-box {
      margin: 0;
      width: 18px;
      height: 18px;
      border-radius: 4px;
      background-color: var(--color-primary-light);
      border: 1px solid var(--color-primary);
      box-shadow: none !important;
      padding: 2px;
    }

    &.Checkbox:hover {
      .Checkbox-box {
        background-color: var(--color-primary-light);
      }
    }

    &.is-checked {
      .Checkbox-box {
        background-color: var(--color-primary) !important;

        svg {
          color: var(--color-primary-light);
        }
      }
    }
  }

  .selected-keyword {
    color: var(--color-primary);
    font-weight: 600;
    margin-left: 8px;
  }
}
/*======== RESPONSIVENESS ======= */
@media (max-width: 729px) {
  .order-drawer-puller_box {
    height: 2.25rem;
    width: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .pages-manager-word-text {
      display: none !important;
    }

    .order-drawer-puller_icon,
    .order-drawer-puller_text {
      display: none;
    }
  }

  .order-drawer-puller_mobile_icon {
    display: inline-block !important;
  }
}

/*======== ANIMATION ======= */
@keyframes to_right {
  0% {
    right: 4px;
  }
  50% {
    right: 14px;
  }
  100% {
    right: 4px;
  }
}

@keyframes swipe_to_right {
  0% {
    left: 0;
  }
  100% {
    left: 40vw;
  }
}
