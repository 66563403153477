.setting-field-container-component {
  position: relative;
  min-height: 60px;
  display: flex;
  align-items: center;
  margin: 15px;
  padding: 10px;
  border: 1px solid #777;
  border-radius: 5px;

  &:not(:first-child) {
    margin-top: 0;
  }

  .setting-field-label {
    position: absolute;
    top: -10px;
    left: 9px;
    font-size: 14px;
    padding: 0 4px;
    background-color: #fff;
    color: #ffa500;
  }

  .setting-field-options-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .setting-field-option {
    display: inline-block;

    label {
      cursor: pointer;
    }

    .setting-field-option_placeholder {
      margin-left: 5px;
      font-size: 14px;
      color: #777;
    }

    input {
      &:focus {
        box-shadow: none;
      }
    }

    input[type="radio"] {
      margin-left: 15px;
      cursor: pointer;

      &:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
      }

      &:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #ffa500;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 2px solid #bbb;
      }
    }
  }
}
