#textfield-with-unit-main {
  position: relative;
  display: flex;
  flex-direction: row;

  .Mui-disabled {
    color: rgba(0, 0, 0, 0.6);
  }
}

#textfield-with-unit-textfield {
  width: calc(100% - 95px);
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333;

  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
  }
}

#textfield-with-unit-dropdown-form-control {
  height: 40px;
  position: absolute;
  right: 10px;
  width: 65px;
  top: 12px;
}

#textfield-with-unit-dropdown {
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  text-transform: capitalize;
}
