@import "./mixins";

$used_font_family: Assistant, system-ui, BlinkMacSystemFont, -apple-system,
  Segoe UI, Roboto, Helvetica, Arial, sans-serif;

.settings-slider-dialog_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 11px;
  padding-left: 16px;
  font-family: Assistant, system-ui, BlinkMacSystemFont, -apple-system, Segoe UI,
    Roboto, Helvetica, Arial, sans-serif;
  border-bottom: 1px solid #eee;
  text-transform: capitalize;
  height: 46px;

  h3 {
    span {
      font-weight: 400;
      font-size: 14px;
    }
  }

  .settings-slider-dialog_close_btn {
    margin-bottom: 0;
  }

  .settings-slider-dialog_close_btn:active {
    background-color: #ebebeb;
    border-radius: 50%;
  }
}

.settings-slider-dialog_divider {
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
  // margin: 5px auto 25px;
}

.settings-slider_content {
  height: 100%;
  position: relative;
  font-family: $used_font_family;
  padding: 0px;
  padding-top: 0;
  scroll-behavior: smooth;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .settings-slider-area-container {
    padding: 15px;
    flex-grow: 1;
  }

  .settings-slider-decimal-points-text-field_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  #settings-slider-decimal-points-text-field {
    width: 30px;
    text-align: center;

    &:focus {
      box-shadow: none;
    }
  }
}

.settings-slider-icon {
  position: absolute;
  top: 50%;
  left: 0px;
  background-color: #fff;
  display: flex;
  padding: 0.5rem;
  z-index: 1301;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 0.5rem 0.5rem 0;
  color: #555;
}

.settings-slider-puller_box_open {
  transition: 0.2s ease-in-out background-color,
    transform 0.5s cubic-bezier(0, 0, 0.2, 1) 0s;
}

.settings-slider-puller_box_close {
  border-radius: 0.5rem;
  transition: 0.2s ease-in-out background-color,
    transform 0.5s cubic-bezier(0.4, 0, 0.6, 1) 0s;
}

.settings-slider-buttons-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  background-color: #fff;
  padding: 15px;
  z-index: 4;
  position: fixed;
  bottom: 0;

  .settings-slider-submit-btn {
    @include submit-btn;
    color: #fff;

    &:disabled {
      background-color: #dddddd;
      border-color: #dddddd;
      color: #fff;
    }
  }
}
