@import "./mixins";

$used_font_family: Assistant, system-ui, BlinkMacSystemFont, -apple-system,
  Segoe UI, Roboto, Helvetica, Arial, sans-serif;

.areas-slider-dialog {
  .selected-checkbox_container {
    justify-content: center;
    padding: 15px 0;

    .selected-keyword {
      font-weight: 500;
    }
  }

  .disabled_checkbox {
    opacity: 0.3;
    pointer-events: none;
  }
}

.areas-slider-puller_box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: fit-content;
  left: 0;
  top: 61%;
  z-index: 4;
  transform: translate(0, -50%);
  background-color: #e3e2fe;
  border-radius: 0px 8px 8px 0px;
  font-family: $used_font_family;
  transition: 1000 ease-in-out width;

  .areas-slider-word-text {
    width: 0;
    font-size: 16px;
    margin-bottom: 3px;
    overflow: hidden;
    white-space: nowrap;
    transition: 0.2s ease-in-out width;
  }

  &:hover {
    cursor: pointer;
    background-color: #d1d0f7;

    .areas-slider-word-text {
      width: 115px;
    }
  }
}

.areas-slider-icon {
  position: absolute;
  top: 50%;
  left: 0px;
  background-color: #fff;
  display: flex;
  padding: 0.5rem;
  z-index: 1301;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 0.5rem 0.5rem 0;
  color: #555;
}

.areas-slider-puller_box_open {
  transition: 0.2s ease-in-out background-color,
    transform 0.5s cubic-bezier(0, 0, 0.2, 1) 0s;
}

.areas-slider-puller_box_close {
  border-radius: 0.5rem;
  transition: 0.2s ease-in-out background-color,
    transform 0.5s cubic-bezier(0.4, 0, 0.6, 1) 0s;
}

.areas-slider-puller_icon {
  color: #868686;
  transition: 0.5s ease-in-out all 0.1s;
}

.areas-slider-dialog_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 11px;
  padding-left: 16px;
  font-family: Assistant, system-ui, BlinkMacSystemFont, -apple-system, Segoe UI,
    Roboto, Helvetica, Arial, sans-serif;
  border-bottom: 1px solid #eee;
  text-transform: capitalize;
  height: 46px;

  .areas-slider-dialog_close_btn {
    margin-bottom: 0;
  }

  .areas-slider-dialog_close_btn:active {
    background-color: #ebebeb;
    border-radius: 50%;
  }
}

.areas-slider-dialog_divider {
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
  // margin: 5px auto 25px;
}

.areas-slider_content {
  height: 100%;
  position: relative;
  font-family: $used_font_family;
  padding: 0px;
  padding-top: 0;
  scroll-behavior: smooth;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .area-slider-area-container {
    padding: 15px;
    flex-grow: 1;
  }
}

.areas-slider-icon-container {
  display: flex;
  align-items: center;
  width: 28px;
  height: 28px;

  .areas-slider-icon {
    width: 100%;
    height: 100%;
  }
}

.areas-slider-add-area-button_container {
  width: 100%;
  position: sticky;
  padding: 15px;
  bottom: 0;
  z-index: 1;
  background-color: #fff;

  .areas-slider-add-area-button {
    width: 100%;
    height: 36px;
    min-height: 36px;
    outline: none;
    overflow: hidden;

    .areas-slider-add-area-button_start {
      position: relative;
      top: 18px;
      transition: 0.2s all ease-in-out;
    }

    .areas-slider-add-area-button_end {
      position: relative;
      top: -41px;
      font-size: 20px;
      transition: 0.2s all ease-in-out;
    }

    &:hover {
      .areas-slider-add-area-button_start {
        top: 41px;
      }

      .areas-slider-add-area-button_end {
        top: -7px;
      }

      background-color: var(--color-gray-10);
    }

    &:active {
      border-color: #6965db;
    }
  }

  #areas-slider-add-area-button {
    @include upload_popup_btn();
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
}

.area-slider-floating-save-discard {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 16px);
}

#area-slider-selecting-tools-dropdown {
  margin-bottom: 1.5rem;
  .MuiSelect-select {
    img {
      display: none;
    }
  }
}

#area-slider-selecting-tool {
  text-transform: capitalize;
  justify-content: space-between;

  img {
    width: 1.5em;
  }
}

#area-slider-selecting-tool.Mui-selected {
  background-color: #e3e2fe60;
}

#area-slider-selecting-tool.area-slider-selecting-tool_rectangle {
  img {
    width: 1.3em;
  }
}

.area-slider-scroll-btn {
  position: sticky;
  width: 50px;
  height: 50px;
  padding: 10px;
  background-color: rgba(74, 71, 177, 0.03);
  border: 1px solid rgba(74, 71, 177, 0.5);
  cursor: pointer;
  bottom: 70px;
  left: 85%;
  color: rgb(74, 71, 177);
  border-radius: 50%;
  z-index: -1;
  opacity: 0;
  transition: 0.2s ease-in-out all;

  &:hover {
    background-color: rgba(74, 71, 177, 0.1);
  }

  &:active {
    border-color: rgba(74, 71, 177, 0.7);
  }
}

.area-slider-scroll-btn_visible {
  z-index: 1;
  opacity: 1;
}

.area-slider-scroll-btn_animated {
  svg {
    position: relative;
    animation: to_top 0.5s ease-in-out 1 forwards;
    transform: (10px, 100px);
  }
}
/* RESPONSIVENESS */
@media (max-width: 729px) {
  .areas-slider-puller_box {
    height: 2.25rem;
    width: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .areas-slider-word-text {
      display: none !important;
    }

    .areas-slider-puller_icon,
    .areas-slider-puller_text {
      display: none;
    }
  }
}

/*---------- Animation ---------- */
@keyframes to_top {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0px);
  }
}
