//COLORS
$primary-color: #ff6347;
$disable-color: #ffac9e;
$hover-color: #d3523b;

.delete-button-component {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: $primary-color;
  border: 1px solid $primary-color;
  color: #fff;
  border-radius: 3px;
  padding: 2px;
  transition: 0.1s ease-in-out border;

  &:hover {
    border: 1px solid $hover-color;
  }
}

.delete-button_disabled {
  background-color: $disable-color;
  border: 1px solid $disable-color;
  pointer-events: none;
}
