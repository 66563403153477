@import "open-color/open-color";
@import "../css/variables.module";

.excalidraw {
  .polygon-tool-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--keybinding-color);
    border: 0;
    outline: 0;
    background-color: transparent;
    width: 36px;
    height: 36px;
    border-radius: 0.5rem;
    position: relative;

    &:hover {
      cursor: pointer;
      background-color: var(--button-hover-bg);
    }

    &:active {
      background-color: var(--color-primary-light);
      color: var(--color-primary-darker);
      background: var(--color-primary-light);
      border: 1px solid var(--color-primary-darkest);

    .polygon-shortcut {
      color: #7a7a7a;
    }
    }

    .polygon-radio-input {
      position: absolute;
      visibility: hidden;
      
      &:checked + .polygon-shortcut {
        visibility: visible;
      }
    }

    .polygon-shortcut {
      position: absolute;
      right: 5px;
      bottom: 4px;
      font-size: 0.625rem;
    }

    svg {
      width: 50%;
      color: var(--icon-fill-color);
      color: #555;
      width: 16.6px;
      height: 16.6px;
    }
  }

  .regular-button {
    background-color: transparent !important;

    &:hover {
      background-color: var(--button-hover-bg) !important;
    }
    &:active {
      background-color:var(--color-primary-light) !important;
    }
    svg {
      color: var(--color-gray-80) !important;
    }
  }
  .polygon-tool-button_active {
    background-color: var(--color-primary-light);
    color: var(--color-primary-darker);

    .polygon-shortcut {
      color: #7a7a7a;
    }
    
    svg {
    color: var(--color-primary-darker)

    }

    &:hover {
      color: var(--icon-fill-color);
      background-color: #e3e2fe;
    }

    &:active {
      background: var(--color-primary-light);
      border: 1px solid var(--color-primary-darkest);
    }
  }
  .layer-ui__wrapper.animate {
    transition: width 0.1s ease-in-out;
  }
  .layer-ui__wrapper {
    // when the rightside sidebar is docked, we need to resize the UI by its
    // width, making the nested UI content shift to the left. To do this,
    // we need the UI container to actually have dimensions set, but
    // then we also need to disable pointer events else the canvas below
    // wouldn't be interactive.
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: var(--zIndex-layerUI);

    &__top-right {
      display: flex;
      gap: 0.75rem;
    }

    &__footer {
      width: 100%;

      &-right {
        z-index: 100;
        display: flex;
      }
    }

    .zen-mode-transition {
      transition: transform 0.5s ease-in-out;

      :root[dir="ltr"] &.transition-left {
        transform: translate(-999px, 0);
      }

      :root[dir="ltr"] &.transition-right {
        transform: translate(999px, 0);
      }

      :root[dir="rtl"] &.transition-left {
        transform: translate(999px, 0);
      }

      :root[dir="rtl"] &.transition-right {
        transform: translate(-999px, 0);
      }

      &.layer-ui__wrapper__footer-left--transition-bottom {
        transform: translate(0, 92px);
      }
    }

    .disable-zen-mode {
      height: 30px;
      position: absolute;
      bottom: 10px;
      [dir="ltr"] & {
        right: 15px;
      }
      [dir="rtl"] & {
        left: 15px;
      }
      font-size: 10px;
      padding: 10px;
      font-weight: 500;
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s linear 0s, opacity 0.5s;

      &--visible {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s linear 300ms, opacity 0.5s;
        transition-delay: 0.8s;
      }
    }

    .layer-ui__wrapper__footer-left,
    .layer-ui__wrapper__footer-right,
    .disable-zen-mode--visible {
      pointer-events: all;
    }

    .layer-ui__wrapper__footer-right {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
